import React from 'react';
import classNames from 'classnames';
import * as style from './index.module.scss';

interface Props {
    children: any;
    hide?: boolean;
    size?: 'small' | 'large' | null;
    noLeftColor?: boolean;
}

const Tooltip = ({
    children,
    size = null,
    hide = false,
    noLeftColor = false,
}: Props) => {
    return (
        <p
            className={classNames(
                style.tooltip,
                hide && style.hide,
                size != null && style[size],
                noLeftColor === true && style.noLeftColor
            )}
        >
            {children}
        </p>
    );
};

export default React.memo(Tooltip);
