import React from 'react';
import classNames from 'classnames';

import * as style from './Checkbox.module.scss';

interface Props {
    id: string;
    name: string;
    isResponsive?: boolean;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isChecked?: boolean;
    smallerFont?: boolean;
    isDisabled?: boolean;
    disabled?: boolean;
    className?: string;
    children?: any;
    size?: 'small' | 'medium' | 'large';
}

const Checkbox = ({
    id,
    name,
    isResponsive = false,
    value,
    onChange,
    isChecked,
    smallerFont,
    isDisabled,
    disabled = false,
    className,
    children,
    size,
}: Props) => (
    <div
        className={classNames(
            style.checkbox,
            isResponsive && style.isResponsive,
            size != null && style[size]
        )}
    >
        <input
            id={id}
            name={name}
            type='checkbox'
            value={value}
            checked={isChecked}
            onChange={onChange}
            disabled={isDisabled === true || disabled === true}
            readOnly={onChange == null}
        />
        <label
            htmlFor={id}
            className={classNames(
                isChecked && style.isSelected,
                (isDisabled || disabled) && style.isDisabled,
                className !== undefined && className
            )}
            title={isChecked ? 'Disable' : 'Enable'}
        >
            <span className={style.spanCheckbox}>
                <svg
                    focusable='false'
                    viewBox='0 0 24 24'
                    aria-hidden='true'
                    role='presentation'
                >
                    {isChecked ? (
                        <path d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z' />
                    ) : (
                        <path d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z' />
                    )}
                </svg>
            </span>
            <span className={classNames(smallerFont && style.smallerFont)}>
                {children}
            </span>
        </label>
    </div>
);

export default React.memo(Checkbox);
