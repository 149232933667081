import React, { useCallback } from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import localization from '../../localization';
import SEO from '../seo';
import * as style from './index.module.scss';
import Checkbox from '../Checkbox';
import config from '../../config';
import Layout from '../Layout';
import Tooltip from '../Tooltip';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { signInFinish, userSet } from '../../state/reducers/globalReducer';

const isBrowser = typeof window !== 'undefined';

let key: string | null = null;
let userId: string | null = null;
let organisationId: string | null = null;

interface Props {
    location: any;
    language: string;
}

const SignUp = ({ location, language }: Props) => {
    // Redux
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const isSigningIn = useAppSelector((state) => state.global.isSigningIn);
    const user = useAppSelector((state) => state.global.user);
    const [recaptcha, setRecaptcha] = React.useState('');
    const recaptchaRef = React.useRef();
    const refIsRemoved = React.useRef(false);
    const dispatch = useAppDispatch();

    const setUser = useCallback((user) => dispatch(userSet(user)), [dispatch]);

    const setSignInFinish = useCallback(
        (token) => dispatch(signInFinish(token)),
        [dispatch]
    );

    // Check if invitation
    if (
        isBrowser &&
        location != null &&
        location.search != null &&
        location.search.indexOf('=') > 0
    ) {
        const urlSplit = location.search.split('=');
        if (urlSplit.length > 2) {
            try {
                userId = urlSplit[1].replace('&o', '');
                organisationId = urlSplit[2].replace('&k', '');
                key = urlSplit[3];
            } catch (err) {
                console.log(err);
            }
        }
    }

    const isInvitation =
        key != null &&
        organisationId != null &&
        userId != null &&
        key.length > 1 &&
        organisationId.length > 1 &&
        userId.length > 0;

    // Form values
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [acceptedTerms, setAcceptedTerms] = React.useState(false);
    const inputNameRef = React.useRef();

    // Logic for signing up
    const [isSigningUp, setIsSigningUp] = React.useState(false);
    const [signUpError, setSignUpError] = React.useState<null | number>(null);

    // Logic for invitation
    const [isFetchingInvitation, setIsFetchingInvitation] =
        React.useState(isInvitation);
    const [invitationUser, setInvitationUser] = React.useState<any>(null);

    const onRecaptchaChange = (val: any) => {
        setRecaptcha(val);
    };

    const onRecaptchaExpired = () => {
        setRecaptcha('');
    };

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    React.useEffect(() => {
        const fetchInvitationAsync = async () => {
            setIsFetchingInvitation(true);
            try {
                const res = await axios.get(
                    `${config.apiBaseUrl}/organisation/${organisationId}/invitation/${userId}/${key}`
                );
                if (!refIsRemoved.current) {
                    setInvitationUser(res.data);
                    setEmail(res.data.email);
                }
                // console.log(res.data)
            } catch (err) {}
            if (!refIsRemoved.current) {
                setIsFetchingInvitation(false);
            }
        };
        if (isInvitation) {
            fetchInvitationAsync();
        }
    }, [isInvitation]);

    const signupAsync = async (e: any = null) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setIsSigningUp(true);
        let token: string | null = null;
        let user: any = null;
        let error: number | null = null;
        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/account/signup`,
                isInvitation
                    ? {
                          name,
                          email,
                          password,
                          confirmPassword: password2,
                          acceptedTerms,
                          key,
                          userId,
                          organisationId,
                          language,
                      }
                    : {
                          name,
                          email,
                          password,
                          confirmPassword: password2,
                          acceptedTerms,
                          recaptcha,
                          language,
                      },
                {
                    withCredentials: true,
                }
            );
            if (response.data.token && response.data.user) {
                token = response.data.token;
                user = response.data.user;
            }
        } catch (err: any) {
            if (err && err.response && err.response.status) {
                error = err.response.status;
            } else {
                console.error(err);
                error = 1;
            }
        }

        setSignUpError(error);
        setSignInFinish(token);
        if (user != null) {
            setUser(user);
            if (!user.isEmailVerified) {
                await navigate(`/${language}/email-verification`);
            } else {
                await navigate(`/account/overview`);
            }
        } else {
            setIsSigningUp(false);
        }
    };

    React.useEffect(() => {
        if (signUpError != null) {
            setRecaptcha('');
            if (recaptchaRef && recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
        }
    }, [signUpError]);

    React.useEffect(() => {
        if (inputNameRef.current) {
            inputNameRef.current.focus();
        }
    }, [isFetchingInvitation]);

    const isEmailVerified =
        user?.isEmailVerified != null && user?.isEmailVerified === true;
    const hasUser = user != null;

    React.useLayoutEffect(() => {
        if (isSignedIn && hasUser && !isSigningIn) {
            if (!isEmailVerified) {
                console.log('Redirect to /email-verification');
                navigate(`/${language}/email-verification`);
            } else {
                // console.log(`Redirect to /app/dashboard`)
                navigate(`/account/overview`);
            }
        }
    }, [hasUser, isSigningIn, isSignedIn, isEmailVerified, language]);

    const onSubmitForm = (e) => {
        if (e) {
            e.preventDefault();
        }
        setSignUpError(null);
        signupAsync();
        // setIsSigningUp(true)
    };

    const isValidForm =
        password.length > 5 &&
        password === password2 &&
        email.length > 2 &&
        email.indexOf('@') > 0 &&
        acceptedTerms &&
        (recaptcha.length > 1 || isInvitation);

    const hasCorrectPasswordLength = password.length > 5;

    const renderValidationErrorMessage = () => {
        if (
            password.length > 0 &&
            password2.length > 0 &&
            password !== password2
        ) {
            return localization[language].register.labelPasswordMismatch;
        }

        return '';
    };

    const renderError = (error: null | number) => {
        if (error === null) {
            return null;
        }
        if (error === 409) {
            return <>{localization[language].register.labelEmailInUse}</>;
        }
        return <>{localization[language].signup.labelError}</>;
    };

    if (!isBrowser) {
        return (
            <>
                <SEO
                    title={localization[language].register.title}
                    description={localization[language].register.title}
                />
                <Layout>
                    <div className={style.signUpPage}></div>
                </Layout>
            </>
        );
    }

    if (isFetchingInvitation) {
        return (
            <>
                <SEO
                    title={localization[language].register.title}
                    description={localization[language].register.title}
                />
                <Layout>
                    <div className={style.signUpPage}>
                        <h2>
                            {
                                localization[language].register
                                    .labelFetchingInvitation
                            }
                        </h2>
                    </div>
                </Layout>
            </>
        );
    }

    return (
        <>
            <SEO
                title={localization[language].register.title}
                description={localization[language].register.title}
            />
            <Layout>
                <div className={style.signUpPage}>
                    <form onSubmit={onSubmitForm}>
                        <h2>{localization[language].register.title}</h2>
                        <div className={style.card}>
                            <div className={style.item}>
                                <label className={style.label} htmlFor='name'>
                                    {localization[language].form.labelName}
                                </label>
                                <input
                                    ref={inputNameRef}
                                    type='text'
                                    id='name'
                                    name='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className={style.item}>
                                <label className={style.label} htmlFor='email'>
                                    {localization[language].form.labelEmail}
                                </label>
                                <input
                                    type='email'
                                    id='email'
                                    name='email'
                                    value={email}
                                    readOnly={isInvitation}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            {invitationUser != null &&
                                invitationUser.organisationName != null && (
                                    <div className={style.item}>
                                        <label
                                            className={style.label}
                                            htmlFor='text'
                                        >
                                            {
                                                localization[language].create
                                                    .labelOrganisation
                                            }
                                        </label>
                                        <input
                                            type='text'
                                            id='organisationName'
                                            name='organisationName'
                                            value={
                                                invitationUser.organisationName
                                            }
                                            readOnly
                                        />
                                    </div>
                                )}
                            <div className={style.item}>
                                <label
                                    htmlFor='password'
                                    className={style.label}
                                >
                                    {
                                        localization[language].register
                                            .labelPassword
                                    }
                                </label>
                                {!hasCorrectPasswordLength && (
                                    <Tooltip>
                                        {
                                            localization[language].register
                                                .tooltipPassword
                                        }
                                    </Tooltip>
                                )}
                                <input
                                    type='password'
                                    id='password'
                                    name='password'
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    autoComplete='new-password'
                                />
                            </div>
                            <div className={style.item}>
                                <label
                                    htmlFor='password2'
                                    className={style.label}
                                >
                                    {
                                        localization[language].register
                                            .labelConfirmPassword
                                    }
                                </label>
                                {renderValidationErrorMessage().length > 0 && (
                                    <Tooltip>
                                        {renderValidationErrorMessage()}
                                    </Tooltip>
                                )}
                                <input
                                    type='password'
                                    id='password2'
                                    name='password2'
                                    value={password2}
                                    onChange={(e) =>
                                        setPassword2(e.target.value)
                                    }
                                    autoComplete='new-password'
                                />
                            </div>
                            <div
                                className={classNames(style.item, style.terms)}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        setAcceptedTerms(e.target.checked)
                                    }
                                    isChecked={acceptedTerms}
                                    id='terms'
                                    name='terms'
                                    size='small'
                                >
                                    {localization[language].form.readAndAgree}{' '}
                                    <a
                                        href={`/${language}/legal/organisation/terms-of-use/`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {localization[language].form.termsOfUse}
                                    </a>
                                    ,{' '}
                                    <a
                                        href={`/${language}/legal/organisation/dpa/`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {localization[language].form.dpa}
                                    </a>{' '}
                                    {localization[language].form.and}{' '}
                                    <a
                                        href={`/${language}/legal/organisation/privacy-policy/`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {
                                            localization[language].form
                                                .privacyPolicy
                                        }
                                    </a>
                                </Checkbox>
                            </div>
                            {isBrowser && !isInvitation && (
                                <div className={style.item}>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey='6LdJGy8aAAAAAN2VBM6dKFOwrur8BEvhde-4m4vI'
                                        onChange={onRecaptchaChange}
                                        onExpired={onRecaptchaExpired}
                                    />
                                </div>
                            )}

                            {/* <div>
                            <Checkbox
                                onChange={onTermsChange}
                                isChecked={acceptedTerms}
                                id="terms"
                                name="terms"
                            >
                                {localization[language].register.labelCookies}
                            </Checkbox>
                        </div> */}

                            <p
                                className={classNames({
                                    [style.error]: true,
                                    [style.hasError]:
                                        signUpError !== null && !isSigningUp,
                                })}
                            >
                                {renderError(signUpError)}
                            </p>

                            <input
                                className='btn'
                                type='submit'
                                value={
                                    isSigningUp
                                        ? localization[language].register
                                              .labelCreating
                                        : localization[language].password
                                              .labelContinue
                                }
                                onClick={onSubmitForm}
                                disabled={!isValidForm || isSigningUp}
                            />
                        </div>
                    </form>
                </div>
            </Layout>
        </>
    );
};

export default React.memo(SignUp);
